<script setup lang="ts">
import type { IForecastAssumption } from '@reports'
import DetailsSection from './DetailsSection.vue'
import { toast as Vue3Toasity } from 'vue3-toastify'

withDefaults(
  defineProps<{
    assumption: IForecastAssumption
    canView: boolean
    divisionId: string
  }>(),
  {
    canView: true,
  },
)

const toast = useToast({
  position: Vue3Toasity.POSITION.BOTTOM_LEFT,
  toastClassName: 'toast-cashflow-toggle',
})

const viewing = defineModel<true | undefined>('viewing')
const onView = () => {
  //show a toast
  if (viewing.value) {
    viewing.value = undefined
    setTimeout(() => {
      toast.success('Showing consolidated cashflow')
    }, 300)
  } else {
    viewing.value = true
    setTimeout(() => {
      toast.success('Showing single lease cashflow')
    }, 300)
  }
}
</script>

<template>
  <Scrollbar class="absolute inset-0 w-full">
    <div class="mb-10 flex w-full flex-col gap-4 p-6">
      <Button
        color="link"
        class="line-clamp-2 text-base"
        :to="`/division/${divisionId}/lease/${assumption.leaseId}/lease`"
      >
        {{ assumption.leaseName }}
      </Button>
      <Button
        v-if="canView"
        :color="viewing ? 'secondary' : 'primary'"
        @click="onView()"
      >
        <Icon
          :name="viewing ? 'ConsolidateCashflow' : 'Cashflow'"
          class="text-lg"
        />
        {{
          viewing
            ? 'View consolidated cashflow'
            : 'View cashflow for this lease '
        }}
      </Button>
      <DetailsSection
        v-for="group in assumption.fieldGroups"
        :key="group.name"
        :group="group"
      />
    </div>
  </Scrollbar>
</template>
